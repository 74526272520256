<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center pt-3"
    >
      <b-spinner label="Loading..." />
    </div>
    <b-card-group
      v-else
      deck
      class="justify-content-around"
    >
      <b-card
        v-for="boat in boats"
        :key="boat.id"
        no-body
        img-height="240"
        :img-src="boat.picture"
        style="max-width: 560px;"
        img-alt="Image"
        img-top
      >
        <b-card-body>
          <b-card-title class="text-primary">{{ boat.name }}</b-card-title>
          <b-card-text>{{ boat.description }}</b-card-text>
        </b-card-body>

        <b-list-group flush>
          <b-list-group-item>
            <strong>Length/Tonnage:</strong> {{ boat.length }} foot / {{ boat.tonnage }} Gross Tons
          </b-list-group-item>
          <b-list-group-item>
            <strong>Capacity:</strong> {{ boat.capacity }} Passengers
          </b-list-group-item>
          <b-list-group-item>
            <strong>Specialty:</strong> {{ boat.specialty }}
          </b-list-group-item>
        </b-list-group>

        <b-card-body class="d-flex flex-column-reverse">
          <b-button
            v-b-modal.edit-modal
            block
            variant="success"
            @click="openEditModal(boat)"
          >
            <span class="text-light">Edit details</span>
          </b-button>
        </b-card-body>
      </b-card>

      <b-modal
        id="edit-modal"
        title="Edit boat details"
        ok-title="Save"
        cancel-variant="outline-secondary"
        @ok="editSubmit"
      >
        <b-form>
          <validation-observer ref="editValidation">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="edited.name"
                  placeholder="Enter boat name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="max:250"
              >
                <b-form-textarea 
                  v-model="edited.description"
                  placeholder="Enter boat description"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Picture"
              label-for="picture"
            >
              <b-form-file
                v-model="edited.picture"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
            <b-form-group
              label="Length"
              label-for="length"
            >
              <b-form-input
                v-model="edited.length"
                placeholder="Enter boat length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              label="Tonnage"
              label-for="tonnage"
            >
              <b-form-input
                v-model="edited.tonnage"
                placeholder="Enter boat tonnage"
                type="number"
              />
            </b-form-group>
            <b-form-group
              label="Capacity"
              label-for="capacity"
            >
              <b-form-input
                v-model="edited.capacity"
                placeholder="Enter boat capacity"
                type="number"
              />
            </b-form-group>
            <b-form-group
              label="Specialty"
              label-for="specialty"
            >
              <validation-provider
                #default="{ errors }"
                name="specialty"
                rules="max:250"
              >
                <b-form-textarea 
                  v-model="edited.specialty"
                  placeholder="Enter boat specialty"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Maintenance from date:" label-for="input-name">
              <validation-provider #default="{ errors }" name="maintenance_from" rules="required">
                <b-form-datepicker
                        id="maintenance_from"
                        :min="minDate"
                        v-model="edited.maintenance_from"
                        locale="en"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Maintenance to date:" label-for="input-name">
              <validation-provider #default="{ errors }" name="maintenance_to" rules="required">
                <b-form-datepicker
                        id="maintenance_to"
                        :min="minDate"
                        v-model="edited.maintenance_to"
                        locale="en"

                        @context="onContext"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </validation-observer>
        </b-form>

        <template #modal-footer>
          <b-button
            variant="outline-secondary"
            class="float-right"
            @click="$bvModal.hide('edit-modal')"
          >
            Close
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            @click="editSubmit"
            :disabled="isEditLoading"
          >
            <b-spinner
              v-if="isEditLoading"
              label="Spinning"
              small
            />
            <span v-else>
              Save
            </span>
          </b-button>
        </template>
      </b-modal>
    </b-card-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { max } from "@/libs/validations"
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BCardGroup,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { toastSuccess, toastFailure } from '@/libs/toastification'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BCardGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  data() {
    return {
      minDate: new Date(),
      isLoading: true,
      edited: {
        name: null,
        description: null,
        picture: null,
        length: null,
        tonnage: null,
        capacity: null,
        specialty: null,
        maintenance_from: null,
        maintenance_to: null,
      },
      temporaryPicture: null,
      isEditLoading: false,
      required,
      max,
    }
  },
  computed: {
    ...mapGetters({
      boats: 'boats/boats',
    }),
  },
  async mounted() {
    await this.loadBoats().catch(() => { toastFailure('Something went wrong.') })

    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadBoats: 'boats/loadBoats',
      updateBoat: 'boats/updateBoat',
    }),
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },
    openEditModal(boat) {

      console.log('boat.maintenance_from', boat.maintenance_from);

      this.edited.id = boat.id
      this.edited.name = boat.name
      this.edited.description = boat.description
      this.edited.length = boat.length
      this.edited.tonnage = boat.tonnage
      this.edited.capacity = boat.capacity
      this.edited.specialty = boat.specialty

      this.edited.maintenance_from = boat.maintenance_from;
      this.edited.maintenance_to = boat.maintenance_to;
    },
    editSubmit() {
      this.$refs.editValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    async submitForm() {
      this.isEditLoading = true

      const formData = new FormData()

      /* eslint-disable-next-line */
      for (const [key, value] of Object.entries(this.edited)) {
        if (value) formData.append(key, value)
      }
      formData.append('_method', 'POST')

      if (this.edited.picture) {
        await this.getTemporaryPicture()
      }

      this.updateBoat({ formData, temporaryPicture: this.temporaryPicture })
        .then(() => {
          toastSuccess('Your changes have been updated.')
          this.isEditLoading = false
          this.$root.$emit('bv::hide::modal', 'edit-modal')
        })
        .catch(() => { toastFailure('Something went wrong.') })
      this.edited.picture = null
      this.temporaryPicture = null
    },
    getTemporaryPicture() {
      return new Promise(resolve => {
        const reader = new FileReader()

        reader.addEventListener('load', () => {
          this.temporaryPicture = reader.result
          resolve()
        }, false)

        if (/\.(jpe?g|png|gif)$/i.test(this.edited.picture.name)) {
          reader.readAsDataURL(this.edited.picture)
        }
      })
    },
  },
}
</script>

<style>

</style>
