import { extend } from 'vee-validate'
import {
  max as rule_max,
} from 'vee-validate/dist/rules'

export const maxValue = extend('max-value', {
  validate(value, { max }) {
    return value < parseInt(max, 10) + 1
  },
  params: ['max'],
  message: (fieldName, placeholders) => {
    return `No more than ${placeholders.max} people for this boat`
  }
})

export const max = extend('max', rule_max)
